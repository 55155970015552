import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import es from 'vuetify/es5/locale/es';

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		options: {
			customProperties: true,
		},
		themes: {
			light: {
				primary: '#E65100',
				secondary: '#424242',
				accent: '#82B1FF',
				error: '#FF5252',
				info: '#2196F3',
				success: '#4CAF50',
				warning: '#FFC107',
				section: '#050B1F',
				icono: 0,
			},
			dark: {
				primary: '#29B6F6',
				secondary: '#424242',
				accent: '#82B1FF',
				error: '#FF5252',
				info: '#2196F3',
				success: '#4CAF50',
				warning: '#FFC107',
				section: '#050B1F',
				icono: 1,
			},
		},
	},
	lang: {
		locales: { es },
		current: 'es',
	},
	icons: {
		iconfont: 'md',
	},
});
