<template>
	<v-app id="inspire">
		<v-app-bar id="home-app-bar" app elevation="1">
			<img
				:src="
					$vuetify.theme.dark
						? require('@/assets/logo-blanco.png')
						: require('@/assets/logo-azul.png')
				"
				contain
				class="mt-1"
				:height="height"
				min-width="200"
			/>

			<v-spacer />

			<v-tabs optional right>
				<v-tab
					v-for="(name, i) in items"
					:key="i"
					:to="{ name }"
					:exact="name === 'Home'"
					:ripple="false"
					active-class="text--primary"
					class="font-weight-bold"
					min-width="80"
					text
				>
					{{ name }}
				</v-tab>
			</v-tabs>
		</v-app-bar>
		<v-content class="pt-auto mt-auto" fluid>
			<v-fade-transition mode="out-in">
				<router-view />
			</v-fade-transition>
		</v-content>
		<v-footer>
			<span>&copy; 2021</span>
			<v-spacer />
			<v-btn
				href="https://www.google.com/maps/place/Dimam+s.a.s/@7.1245575,-73.1296808,20z/data=!4m5!3m4!1s0x0:0x42050359b195d076!8m2!3d7.1246062!4d-73.1297589"
				target="_blank"
				class="mr-2"
				elevation="0"
			>
				<v-icon>place</v-icon>
			</v-btn>

			<span> Carrera 14 # 23-15 Bucaramanga</span>
			<v-spacer />
			<span>gerencia@dimam.com.co</span>
			<v-spacer />
			<span>6333306 - 3118784702</span>
			<v-spacer />
			<v-switch v-model="$vuetify.theme.dark" inset label="Dark" persistent-hint></v-switch>
		</v-footer>
	</v-app>
</template>

<script>
export default {
	name: 'App',
	computed: {
		height() {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
					return 70;
				case 'sm':
					return 90;
				case 'md':
					return 100;
				case 'lg':
					return 200;
				case 'xl':
					return 200;
			}
		},
	},
	data: () => ({
		drawer: null,
		items: ['Dimam', 'About', 'Servicios'],
		dark: 1,
	}),
};
</script>
